import { Injector } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { EnvironmentsService } from './services/environments.service';

/**
 * Replace the main route by removing all paths that are not part of the environment.featureModule
 */
export function onAppInit(injector: Injector): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const router = injector.get(Router);
      const env = injector.get(EnvironmentsService);

      const featureModule = env.config.featureModule;
      if (featureModule !== 'saved-configurations') {
        const featureModuleRoutes: Routes = router.config
          .map(route => {
            if (route.redirectTo && (route.path === '' || route.path === '**')) {
              route.redirectTo = featureModule;
            }
            return route;
          })
          .filter(
            route => route.path === '' || route.path === '**' || route.path === featureModule,
          );
        router.resetConfig(featureModuleRoutes);
      }

      resolve();
    });
  };
}
