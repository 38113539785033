// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// tslint:disable: max-line-length
// tslint:disable: max-line-length
export const environment = {
  production: false,
  apiUrl: 'https://auth.esp-staging.vmware-aws.com/api/auth/v1',
  inputServiceUrl: 'https://api-devlvn.broadcom.net/dev/vcf/tools/input',
  rvToolUrl: 'https://api-devlvn.broadcom.net/dev/vcf/tools/input',
  recommendationServiceUrl: 'https://api-devlvn.broadcom.net/dev/vcf/tools/recomm',
  sfdcUrl : 'https://api-devlvn.broadcom.net/dev/vcf/tools/cmds',
  CLIENT_ID: 'esp-stg-179-wybmx',
  environmentType: 'dev',
  featureModule: 'saved-configurations',
  orgId : 'cd5f8911-03fc-4cc0-b4e0-a532af2c6c08',
  codeChallenge: '7GqmC3rOWGkyEDBlxSVh6xsLi0LZcpnMgtRps_B0Mw8',
  codeChallengeMethod:'S256',
  codeVerifier:'u1adRVd1-dUNkDWcjPF07VOedFXJSX9vH6wu3NO8fP2sADqDcOUM-8Tp_WiT73iUE9m3o8bxYQn2SDp0B_4ZZOj3nY5ctpwWrdlJZeBsvJSsXfm1aOhugnh2v69bbmeV',
  authApiUrl: 'https://console-stg.cloud.vmware.com',
  STATE: 'CXNwLWFwaS1jbGllbnQ=',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
