import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppStringsService } from 'src/app/services/app-strings/app-strings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  @Input() openAccModal: boolean;
  @Output() closeAccModal = new EventEmitter<boolean>();
  currentSection: string;
  appLabels: any;
  accountInfoProfileForm: FormGroup = this.formBuilder.group({
    profile: this.createFormGroup({firstName: 'ABCD', lastName: 'efgh', email: 'abcdefgh@ijkl.com', organization: 'mnop', location: 'qrst', emailNotify: true}),
  });
  accountInfoPwdChangeForm: FormGroup = this.formBuilder.group({
    oldPwd: ['xyz', [Validators.required]],
    newPwd: ['zxr', [Validators.required]],
    newPwdConf: ['zxr', [Validators.required]]
  })
  constructor(public appStringService: AppStringsService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.appLabels = this.appStringService.appStrings;
    this.currentSection = 'profile';
  }

  closeAccHandler(){
    this.closeAccModal.emit(false);
  }
  activateSection(selectedSection: string){
    this.currentSection = selectedSection;
  }
  createFormGroup(userProfile: any){
   return this.formBuilder.group({
      firstName: [userProfile.firstName, [Validators.required]],
      lastName: [userProfile.lastName, [Validators.required]],
      email: [userProfile.email, [Validators.required]],
      organization: [userProfile.organization, [Validators.required]],
      location: [userProfile.location, [Validators.required]],
      emailNotify: [userProfile.emailNotify]
    })
  }
}